:root {
    --cream-white:#F2F4F3;
    --dark-gray: #212529;
    --light-gray: #CFD4DA;
    --black:  #0C0B0A;
    --dark-blue: #00529C;
    --light-blue: #176DB6;
    --dark-yellow: #FAA31B;
    --light-yellow: #FFF3C1;

}

.all {
    color: white;
    background-image: linear-gradient(to bottom left, #3E7CB1, #B175BD);
}

#contact-message {
    size-adjust:unset;
}

.container-body {
    margin-left: 15%;
    margin-right: 15%;
}

.header {
    background-image: url("../../images/profile1.jpg");
    width: 100%;
    height: 550px;
    background-attachment: fixed;
    background-position-y: 15%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    animation: fadeOut;
}

@media only screen and (max-width: 600px) {
    .header {
        background-image: url("../../images/profile1.jpg");
        width: 100%;
        height: 550px;
        background-attachment: scroll;
        background-position-y: 15%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        animation: fadeOut;
    }
}

.header .firstname {
    font-family: 'JetBrains Mono';
    font-size: 9vw;
    animation: 3s slide-right;
    animation-delay: 0s;
}
  
@keyframes slide-right {
    from {
        margin-left: -1000px;
    }

    to {
        margin-left: 0%;
    }
}

.header .lastname {
    font-family: 'JetBrains Mono';
    font-size: 8vw;
    animation: 3s slide-left;
    animation-delay: 0s;
}
  
@keyframes slide-left {
    from {
        padding-left: 200vw;
    }

    to {
        padding-left: 0%;
    }
}

 @keyframes fadeOut {
    0% {transition: opacity ease-out}
    100% {opacity: 0;}
 }

.header-name {
    overflow: hidden;
    margin-right: 10%;
    position: absolute;
    top: 50px;
}

.header-nav {
    display: inline;
    position: absolute;
    bottom: 5%;
}

.header-nav a {
    font-family: 'JetBrains Mono';
    color: white;
}

.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}
.navbar-container .navbar {
    padding: 10px;
    background-color: #222C33;
    color: white;
}

nav {
    font-family: 'JetBrains Mono';
}

nav button {
    color: white;
}

.navbar-container a {
    text-decoration: none;
    color: white;
}

#about {
    font-family: 'JetBrains Mono';
    margin-bottom: 50px;
}

#about ul {
    list-style-type: none;
    padding-left:0;
}

#about h6 {
    color: rgb(10, 10, 10);
    display: inline;
}

#about a {
    text-decoration: none;
    color: darkslategray;
    margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
    #about {
        font-size: 12px;
    }
}

#about a:hover {
    color: black;
}

#about .profile-card {
    padding: 10px;
    background-color: #212529;
    border-radius: 20px 0px 20px 0px;
    box-shadow: 6.4px 12.8px 12.8px var(--black);
}
.profile-photo {
    padding: 10px;
}

#projects h1 {
    font-family: 'JetBrains Mono';
}

.jrivia {
    margin-bottom: 20px;
    background-color: #3E7CB1;
    border-radius: 20px 20px 20px 0px;
    font-family: 'JetBrains Mono';
}

.jrivia-title {
    color: white;
    text-decoration: none;
    border-radius: 20px 0px 20px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s;
    
}
.jrivia-title:hover {
    color: white;
    background: rgba(0, 0, 0, 0.7);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
}

.jrivia-title h2 {
    height: 100%;
    padding: 10px;
}

.jrivia-description {
    padding: 10px;
}
.jrivia-description a {
    text-decoration: none;
    color: darkslategrey;
}
.jrivia-description a:hover {
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 600px) {
    .jrivia-description p {
        font-size: 12px;
    }
}

.contact {
    font-family: 'JetBrains Mono';
    margin-top: 30px;
}

.contact input, textarea{
    width: 100%;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    resize: none;
}

.contact-button {
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #222C33;
    color: white;
    padding:5px 20px 5px 20px;
}


@media only screen and (max-width: 600px) {
    .contact p {
        font-size: 12px;
    }
}

input:focus, textarea:focus {
    padding-left: 10px;
    padding-right: 10px;
    outline:none;
}

#gallery h1 {
    margin-top: 50px;
    font-family: 'JetBrains Mono';
}

.gallery-item {
    padding: 20px;
    position: relative;
}

.gallery-item img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 40vh;
    border-radius: 10px;
}

.gallery-item .caption {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    opacity: 0;
}
.gallery-item .caption p {
    max-width: 100%;
    max-height: 50%;
    position: absolute;
    padding: 10px;
    bottom: 0px;
    overflow-y: hidden;
}

@media only screen and (max-width: 600px) {
    .gallery-item .caption p {
        font-size: 12px;
    }
}


.gallery-item .caption:hover{
    opacity: 1;
    border-radius: 10px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
}

.footer-background {
    margin-top: 50px;
    background-color: #222C33;
    margin-bottom: 0px;
}

.footer {
    padding: 50px;
}

.footer a {
    position: relative;
    top: 0;
    transition: top ease 0.5s;

    text-decoration: none;
    color: white;
}

.footer a:hover {
    top: -5px;
    color: #3E7CB1;
}

.footer p, h6 {
    margin-bottom: 20px;
}

.footer i {
    margin-right: 10px;
}